import { computed, Injectable, signal } from '@angular/core';
import { OperatorFunction, tap } from 'rxjs';

export function setLoaderCounter<T>(
  loader: LoaderService
): OperatorFunction<T, T> {
  return tap({
    next: () => loader.decrementLoader(),
    error: () => loader.decrementLoader(),
  });
}

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  #loaderCounter = signal(0);

  isLoading = computed(() => this.#loaderCounter() > 0);

  incrementLoader(): void {
    this.#loaderCounter.update((count) => count + 1);
  }

  decrementLoader(): void {
    this.#loaderCounter.update((count) => Math.max(0, count - 1));
  }
}
